export default {
  // ==================================================================================
  // This part is for mapping meta data object key names of the webpage name.
  // So you can use this object key name for passing it as the props of the meta component
  // when you render meta component in the every webpage
  // ==================================================================================
  dataKeyMaps: {
    HOME: "home",
    WHY_CHOOSE_CS: "whyChooseCS",
    GET_IN_TOUCH: "getInTouch",
    OUR_STORY: "ourStory",
    FAQ: "faq",
    PRODUCT_CLOSETS: "closets",
    FRANCHISE_OPPORTUNITIES: "franchiseOpp",
    CABINET_DOORS: "cabinetDoors",
    PRIVACY_POLICY: "privacyPolicy",
    VIEW_ALL: "viewAll",
    LOCATIONS: "locations",
    LOCATION_CHANDLER_ARIZONA: "chandlerArizona",
    BLOG: "blog",
    FINANCING: "financing",
    EUROLUX: "eurolux",
    COST:"faqCost",
    WALLBEDS: "faqWallbeds",
    PRODUCTS:"faqProducts"
  },
  // ==================================================================================
  // It is meta data objects for the webpage
  // ==================================================================================
  faqProducts: {
    title: "FAQs - Products",
    description:
      "FAQ's - I would love to see what Classy Closets can do for me. How do I get started?The quickest way to get started is to submit a “Start Your FREE...",
    canonical: "https://classyclosets.com/faq/products",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  faqCost: {
    title: "FAQs - Cost",
    description:
      "FAQ's - I would love to see what Classy Closets can do for me. How do I get started?The quickest way to get started is to submit a “Start Your FREE...",
    canonical: "https://classyclosets.com/faq/cost",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  faqWallbeds: {
    title: "FAQs - Wall Beds",
    description:
      "FAQ's - I would love to see what Classy Closets can do for me. How do I get started?The quickest way to get started is to submit a “Start Your FREE...",
    canonical: "https://classyclosets.com/faq/wall-beds",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  home: {
    title: "Custom Closets & Storage Solutions - Classy Closets",
    description:
      "Classy Closets has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classyclosets.com/",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  locations: {
    title: "Our Locations - Classy Closets",
    description:
      "Classy Closets has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classyclosets.com/view-all-products",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  franchiseOpp: {
    title: "Franchise Opportunities - Classy Closets",
    description:
      "Classy Closets has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classyclosets.com/franchise-opportunities",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  privacyPolicy: {
    title: "Privacy Policy - Classy Closets",
    description:
      "Classy Closets has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classyclosets.com/privacy-policy",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  viewAll: {
    title: "Our Products - Classy Closets",
    description:
      "Classy Closets has been providing its customers with high quality, custom closets and an array of other custom storage solutions for over 35 years.",
    canonical: "https://classyclosets.com/view-all-products",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  whyChooseCS: {
    title: "Why Choose Us - Classy Closets | The Classy Closets Difference",
    description:
      "For over 35 years Classy Closets has been creating custom home storage solutions that are both beautiful and functional. Each custom closet design is...",
    canonical: "https://classyclosets.com/why-choose-classy-closets",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  getInTouch: {
    title: "Get in touch - Classy Closets - Free In-Home Consultation",
    description:
      "Request a FREE In-Home Consultation with one of our expert Professional Design Team Members by completing the form...",
    canonical: "https://classyclosets.com/get-in-touch",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  ourStory: {
    title: "Our Story - Classy Closets - Closet & Storage Solutions",
    description:
      "Classy Closets has been improving the lives of families and businesses through well-organized closet storage solutions designed for optimal living. More...",
    canonical: "https://classyclosets.com/our-story",
    robots:
      "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  faq: {
    title: "FAQs - Classy Closets - Custom Closets & Storage",
    description:
      "Find answers to all your custom closet and storage questions in the Classy Closets FAQ. Learn about design, installation, pricing, and more to help you plan your perfect space.",
    canonical: "https://classyclosets.com/faq",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  blog: {
    title: "Classy Closets Blog - Classy Closets - Helping You Keep Life. Organized.",
    description:
      "Classy Closets Blog - Classy Closets - Helping You Keep Life. Organized.",
    canonical: "https://classyclosets.com/blogs/posts",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  careers: {
    title: "Career Opportunities - Classy Closets",
    description:
      "Classy Closets jobs, careers, and opportunities",
    canonical: "https://classyclosets.com/careers",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  eurolux: {
    title: "Eurolux Sale - Custom Closets by Classy Closets",
    description:
      "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "quartz": {
    title: "Quartz Sale - Up to 40% off Quartz - Classy Closets",
    description:
      "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/quartz",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  mothers: {
    title: "Mother's Day Sale - Classy Closets",
    description:
      "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/mothers",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
    homefield: {
    title: "Homefield Advantage Sale - Custom Closets by Classy Closets",
    description:
      "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  // ==================================================================================
  // This part is the products pages group
  // ==================================================================================
  "closets": {
    title: "Closets - Custom Closets by Classy Closets",
    description:
      "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },

  "homefield": {
    title: "Homefield Advantage Sale - Custom Closets by Classy Closets",
    description:
      "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "wall-beds": {
    title: "Wall Beds - Murphy Beds - Classy Closets",
    description: "At Classy Closets, we feel that there is no room too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/wall-beds",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "pantry": {
    title: "Pantry - Classy Closets",
    description: "At Classy Closets, we feel that there is no Pantry too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/pantry",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "home-offices": {
    title: "Home Offices - Classy Closets",
    description: "At Classy Closets, we feel that there is no Home Office too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/home-offices",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "media-centers": {
    title: "Media Centers - Entertainment Walls - Classy Closets",
    description: "At Classy Closets, we feel that there is no room too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/media-centers",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "libraries": {
    title: "Libraries & Bookshelves - Classy Closets",
    description: "At Classy Closets, we feel that there is no room too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/libraries",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "laundry-rooms": {
    title: "Laundry Rooms - Classy Closets",
    description: "At Classy Closets, we feel that there is no room too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/laundry-rooms",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "garage-cabinets": {
    title: "Garage Cabinets - Classy Closets",
    description: "At Classy Closets, we feel that there is no Garage too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/garage-cabinets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  cabinetDoors: {
    title: "Cabinet Doors - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/products/cabinets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "commercial-offices": {
    title: "Commercial Offices - Classy Closets",
    description: "At Classy Closets, we feel that there is no office too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/commercial-offices",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "kitchen-bath": {
    title: "Kitchen & Bath - Classy Closets",
    description: "At Classy Closets, we feel that there is no kitchen too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/kitchen-bath",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "materials-and-finishes": {
    title: "Materials & Finishes - Classy Closets",
    description: "At Classy Closets, we feel that there is no kitchen too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/materials-and-finsihes",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "luxury-closets": {
    title: "Luxury Closets - Classy Closets",
    description: "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/luxury-closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "kids-closets": {
    title: "Kid's Closets - Classy Closets",
    description: "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/kids-closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "walk-in-closets": {
    title: "Walk-In Closets - Classy Closets",
    description: "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/walk-in-closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "reach-in-closets": {
    title: "Reach-In Closets - Classy Closets",
    description: "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/products/reach-in-closets",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  // ==================================================================================
  // This part is the individual location pages group
  // ==================================================================================
  chandler: {
    title: "Chandler Closets & Custom Cabinetry - Classy Closets",
    description: "Chandler, Arizona is the home of Classy Closets' corporate offices, manufacturing facilities, and one of the most beautiful closet design showrooms in...",
    canonical: "https://classyclosets.com/locations/chandler",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  avondale: {
    title: "Avondale Closets & Custom Cabinetry - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/avondale",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "cave-creek": {
    title: "Cave Creek Closets & Custom Cabinetry - Classy Closets",
    description: "Classy Closets builds custom closets and cabinetry for homeowners in Cave Creek",
    canonical: "https://classyclosets.com/locations/cave-creek",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "white-sulphur-springs": {
    title: "White Sulphur Springs Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in White Sulphur Springs and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/white-sulphur-springs",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "townsend": {
    title: "Townsend Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Townsend and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/townsend",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "lewiston": {
    title: "Lewiston Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Lewiston and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/lewiston",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "lincoln": {
    title: "Lincoln Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Lincoln and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/lincoln",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },

  fortBenton: {
    title: "Fort Benton Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Fort Benton and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/fort-benton",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "dearborn": {
    title: "Dearborn Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Dearborn and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/dearborn",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "cascade": {
    title: "CascadeCustom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Cascade and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/helena",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "butte": {
    title: "Butte Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Butte and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/helena",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "augusta": {
    title: "Augusta Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Augusta and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/augusta",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "helena": {
    title: "Helena Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Helena and the Great Falls area.",
    canonical: "https://classyclosets.com/locations/helena",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "west-lake-hills": {
    title: "West Lake Hills Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in West Lake Hills and the greater Austin area.",
    canonical: "https://classyclosets.com/locations/west-lake-hills",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "pflugerville": {
    title: "Pflugerville Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Pflugerville and the greater Austin area.",
    canonical: "https://classyclosets.com/locations/pflugerville",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "lago-vista": {
    title: "Lago Vista Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Lago Vista and the greater Austin area.",
    canonical: "https://classyclosets.com/locations/lago-vista",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "lakeway": {
    title: "Lakeway Custom Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Lakeway and the greater Austin area.",
    canonical: "https://classyclosets.com/locations/lakeway",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
"dripping-springs": {
    title: "Dripping Springs Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Dripping Springs and the greater Austin area.",
    canonical: "https://classyclosets.com/locations/dripping-springs",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "barton-creek": {
    title: "Barton Creek Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Barton Creek and the greater Austin area.",
    canonical: "https://classyclosets.com/locations/barton-creek",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "bee-cave": {
    title: "Bee Cave Closet Company",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Bee Cave and the greater Austin area.",
    canonical: "https://classyclosets.com/locations/bee-cave",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  austin: {
    title: "Classy Closets of Austin",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Austin",
    canonical: "https://classyclosets.com/locations/austin",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "gold-canyon": {
    title: "Gold Canyon Closets & Custom Cabinetry - Classy Closets",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Gold Canyon",
    canonical: "https://classyclosets.com/locations/gold-canyon",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  surprise: {
    title: "Surprise Closets & Custom Cabinetry - Classy Closets",
    description: "Classy Closets builds custom closets and cabinetry in homes located in Surprise",
    canonical: "https://classyclosets.com/locations/surprise",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "colorado-springs": {
    title: "Colorado Springs Closets & Custom Cabinetry - Classy Closets",
    description: "Colorado Springs is home to one of the most beautiful closet design showrooms in Colorado",
    canonical: "https://classyclosets.com/locations/colorado-springs",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  dallas: {
    title: "Dallas Closets & Custom Cabinetry - Classy Closets",
    description: "Dallas is home to one of the most beautiful closet design showrooms in Texas",
    canonical: "https://classyclosets.com/locations/dallas",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  denver: {
    title: "Denver Closets & Custom Cabinetry - Classy Closets",
    description: "Denver is home to one of the most beautiful closet design showrooms in Colorado",
    canonical: "https://classyclosets.com/locations/denver",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "el-paso": {
    title: "El Paso Closets & Custom Cabinetry - Classy Closets",
    description: "El Paso Texas custom cabinets, closets and storage solutions",
    canonical: "https://classyclosets.com/locations/el-paso",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  flagstaff: {
    title: "Flagstaff Closets & Custom Cabinetry - Classy Closets",
    description: "Flagstaff custom cabinets, closets and storage solutions",
    canonical: "https://classyclosets.com/locations/flagstaff",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "great-falls": {
    title: "Great Falls Closets & Custom Cabinetry - Classy Closets",
    description: "Great Falls Montana custom cabinets, closets and storage solutions",
    canonical: "https://classyclosets.com/locations/great-falls",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "laguna-hills": {
    title: "Laguna Hills Closets & Custom Cabinetry - Classy Closets",
    description: "Laguna Hills custom cabinets, closets and storage solutions",
    canonical: "https://classyclosets.com/locations/laguna-hills",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "lake-havasu": {
    title: "Lake Havasu Closets & Custom Cabinetry - Classy Closets",
    description: "Lake Havasu custom cabinets, closets and storage solutions",
    canonical: "https://classyclosets.com/locations/lake-havasu",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "las-cruces": {
    title: "Las Cruces Closets & Custom Cabinetry - Classy Closets",
    description: "Las Cruces is home to one of the most beautiful closet design showrooms in New Mexico",
    canonical: "https://classyclosets.com/locations/las-cruces",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "las-vegas": {
    title: "Las Vegas Closets & Custom Cabinetry - Classy Closets",
    description: "Las Vegas is home to one of the most beautiful closet design showrooms in Nevada",
    canonical: "https://classyclosets.com/locations/las-vegas",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  mesquite: {
    title: "Mesquite Closets & Custom Cabinetry - Classy Closets",
    description: "Mesquite is home to one of the most beautiful closet design showrooms in Nevada",
    canonical: "https://classyclosets.com/locations/mesquite",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  norton: {
    title: "Norton Closets & Custom Cabinetry - Classy Closets",
    description: "Norton Kansas custom cabinets, closets and storage solutions",
    canonical: "https://classyclosets.com/locations/norton",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  prescott: {
    title: "Prescott Closets & Custom Cabinetry - Classy Closets",
    description: "Prescott Arizona custom cabinets, closets and storage solutions",
    canonical: "https://classyclosets.com/locations/prescott",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "salt-lake-city": {
    title: "Salt Lake City Closets & Custom Cabinetry - Classy Closets",
    description: "Salt Lake City is home to one of the most beautiful closet design showrooms in Utah",
    canonical: "https://classyclosets.com/locations/salt-lake-city",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "san-antonio": {
    title: "San Antonio Closets & Custom Cabinetry - Classy Closets",
    description: "San Antonio closets, custom cabinetry and more",
    canonical: "https://classyclosets.com/locations/san-antonio",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "san-diego": {
    title: "San Diego Closets & Custom Cabinetry - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/san-diego",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  scottsdale: {
    title: "Scottsdale Closets & Custom Cabinetry - Classy Closets",
    description: "Scottsdale Arizona is home to one of the most beautiful closet design showrooms in Arizona",
    canonical: "https://classyclosets.com/locations/scottsdale",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
 "the-woodlands": {
    title: "Custom Closets - Classy Closets of Houston",
    description: "",
    canonical: "https://classyclosets.com/locations/the-woodlands",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  houston: {
    title: "Custom Closets - Classy Closets of Houston",
    description: "",
    canonical: "https://classyclosets.com/locations/houston",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  magnolia: {
    title: "Magnolia Custom Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/magnolia",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  klein: {
    title: "Klein Custom Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/klein",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  tomball: {
    title: "Tomball Custom Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/tomball",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  montgomery: {
    title: "Montgomery Custom Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/montgomery",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  spring: {
    title: "Spring Custom Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/spring",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  conroe: {
    title: "Conroe Custom Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/conroe",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "fountain-hills": {
    title: "Fountain Hills Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/fountain-hills",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  mesa: {
    title: "Mesa Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/mesa",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "queen-creek": {
    title: "Queen Creek Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/queen-creek",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  gilbert: {
    title: "Gilbert Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/gilbert",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  glendale: {
    title: "Glendale Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/glendale",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  goodyear: {
    title: "Goodyear Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/goodyear",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  parker: {
    title: "Parker Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/parker",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  showlow: {
    title: "Showlow Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/showlow",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  tempe: {
    title: "Tempe Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/tempe",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  peoria: {
    title: "Peoria Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/peoria",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  phoenix: {
    title: "Phoenix Closets - Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/phoenix",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "st-george": {
    title: "St. George Classy Closets",
    description: "",
    canonical: "https://classyclosets.com/locations/st-george",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  tucson: {
    title: "Tucson Closets & Custom Cabinetry - Classy Closets",
    description: "Tucson Arizona is home to one of the most beautiful closet design showrooms in Arizona",
    canonical: "https://classyclosets.com/locations/tucson",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  // ==================================================================================
  // Accessibility
  // ==================================================================================
  accessibility: {
    title: "Accessibility statement - Classy Closets",
    description: "Classy Closets is committed to making our website's content accessible and user friendly to everyone",
    canonical: "https://classyclosets.com/accessibility-statement",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  materialsAndFinishes: {
    title: "Materials and finishes - Classy Closets",
    description: "Classy Closets has plenty of options for your materials and finishes needs",
    canonical: "https://classyclosets.com/materials-and-finishes",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "fbLanding": {
    title: "Closets - Custom Closets by Classy Closets",
    description:
      "At Classy Closets, we feel that there is no closet too small or too big that couldn't benefit from one of our designs!",
    canonical: "https://classyclosets.com/landing/social",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "designContest": {
    title: "Classy Closets 2023 Design Contest",
    description:
      "Vote for your favorite design of 2023 from our top designers",
    canonical: "https://classyclosets.com/design-contest",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "financing": {
    title: "",
    description:
      "",
    canonical: "https://classyclosets.com/landing/financing",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "holiday": {
    title: "Classy Holiday Sales Event - Classy Closets",
    description: "Classy Closets is having a holiday sales event! Get 15% off EuroLux Materials! Don't miss out on this limited-time offer.",
    canonical: "https://classyclosets.com/holiday",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  summer: {
    title: "Classy Summer Kick-Off Sale Event - Classy Closets",
    description: "Classy Closets is having a Summer sale event! Get 20% off colored melamine and 15% off white melamine! Don't miss out on this limited-time offer.",
    canonical: "https://classyclosets.com/summer-sale",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  },
  "lola": {
    title: "Lola Garcia - What lola likes - Classy Closets",
    description: "What Lola Likes has teamed up with Classy Closets!",
    canonical: "https://classyclosets.com/whatlolalikes",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
  "360page": {
    title: "Virtual Tour - Classy Closets",
    description: "Take a virtual 360 tour of our collections of our custom kitchen collections",
    canonical: "https://classyclosets.com/360-page",
    robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
  },
};
